import React from "react";
import PropTypes from "prop-types";

import ProjectCertificateListField from "Containers/ProjectCertificateListField";
import ContentLayout from "Components/ContentLayout";

class ProjectSettingsVariable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { organizationId, projectId } = this.props.params;

    return (
      <ContentLayout className="settings">
        <ProjectCertificateListField
          projectId={projectId}
          organizationId={organizationId}
        />
      </ContentLayout>
    );
  }
}

ProjectSettingsVariable.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired
  })
};

export default ProjectSettingsVariable;
