import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Button from "./Button";
import TextAreaField from "./fields/TextAreaField";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

const CertificateFormLayout = styled.form`
  width: 100%;
`;

class CertificateForm extends React.Component {
  constructor(props) {
    super(props);
    this.onKeyChange = this.onKeyChange.bind(this);
    this.onCertificateChange = this.onCertificateChange.bind(this);
    this.onChainChange = this.onChainChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.state = {
      ...this.props.certificate
    };
  }

  onChange(name, value) {
    this.setState({
      [name]: value
    });
    this.props.onChange();
  }

  onCertificateChange(event) {
    this.onChange("certificate", event.target.value);
  }

  onKeyChange(event) {
    this.onChange("key", event.target.value);
  }

  onChainChange(event) {
    this.onChange("chain", event.target.value);
  }

  onSave() {
    this.props.onSave(this.state);
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    const { intl, isLoading } = this.props;
    return (
      <Layout>
        <LiveMessage
          message={intl.formatMessage({ id: "add_certificate" })}
          aria-live="polite"
        />
        <CertificateFormLayout
          id="certificate-add-form"
          aria-labelledby="add-certificate-heading"
        >
          <TextAreaField
            id="project-certificate-private-key-input"
            label={`${intl.formatMessage({ id: "private_key" })}`}
            value={this.state.key || ""}
            onChange={this.onKeyChange}
            error={this.props.errors.key}
          />
          <TextAreaField
            id="project-certificate-public-key-input"
            label={`${intl.formatMessage({ id: "public_key" })}`}
            value={this.state.certificate || ""}
            onChange={this.onCertificateChange}
            error={this.props.errors.certificate}
          />
          <TextAreaField
            id="project-certificate-intermediate-input"
            label={`${intl.formatMessage({
              id: "ssl_chain"
            })}`}
            value={this.state.chain}
            onChange={this.onChainChange}
            error={this.props.errors.chain}
            required={false}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper>
              <Button
                id="project-certificate-save-btn"
                type="submit"
                aria-label={intl.formatMessage({ id: "add_certificate" })}
                onClick={
                  this.state.key && this.state.certificate && this.onSave
                }
              >
                {intl.formatMessage({ id: "add_certificate" })}
              </Button>
              <Button
                id="project-certificate-cancel-btn"
                type="button"
                className="secondary"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={this.onCancel}
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonWrapper>
          )}
        </CertificateFormLayout>
      </Layout>
    );
  }
}

CertificateForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  isJson: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  certificate: PropTypes.object,
  intl: PropTypes.object,
  onChange: PropTypes.func
};

export default injectIntl(CertificateForm);
